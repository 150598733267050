
@import "~bootstrap/scss/bootstrap";

.shadow-panel {
    background: url('../img/shadow-panel.png') no-repeat top center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 20px;
    width: 100%;
    z-index: 99;
}

@fontface{
    font-family: 'FrancoisOne';
    src: url(./fonts/FrancoisOne-Regular.ttf);
}

@fontface{
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-Light.ttf);
}

#navegador {
    background-color: #FA0405;
}

#navegador a {
    color: var(--dark) !important;
}

#navegador a:hover {
    color: var(--white) !important;
}

#contenedor-global {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.container-fluid {
    padding: 0px;
    overflow:hidden;
}

.malla {
  background: url(../img/pattern-bg-1.png) repeat rgba(0,0,0,0.5);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index:-15;
  -webkit-transform:translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.malla-blanca{
  background: url(../img/pattern-bg-1.png) repeat rgba(255,255,255,0.6);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index:-15;
  -webkit-transform:translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.bg-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index:-25;
  -webkit-transform:translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.video_bg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.seccion-rojo{
    background-color: rgba(250,4,5, 0.8);
}

p.h2{
    text-shadow: 2px 2px rgba(0,0,0, .5);
}

#footer{
    background-color: rgba(250,4,5, 1);
}

@include media-breakpoint-up(xs) {
  #prueba {
    font-size: 1.8rem;
  }
}

@include media-breakpoint-up(sm) {
  #prueba {
    font-size: 2.4rem;
  }
}

@include media-breakpoint-up(md) {
  #prueba {
    font-size: 2.6rem;
  }
}

@include media-breakpoint-up(lg) {
  #prueba {
    font-size: 2.8rem;
  }
}

// ######################### //
